import Title from "./Title";
import { values } from "../data";

const Values = () => {
  return (
    <section className="section values" id="values">
      <Title title="my" subTitle="values" />
      <div className="section-center values-center">
        {values.map((value) => {
          const { id, icon, title, text } = value;
          return (
            <article className="value" key={id}>
              <span className="value-icon">
                <i className={icon}></i>
              </span>
              <div className="value-info">
                <h4 className="value-title">{title}</h4>
                <p className="value-text">{text}</p>
              </div>
            </article>
          );
        })}
      </div>
    </section>
  );
};

export default Values;
