import Button from "@mui/material/Button";

const Hero = () => {
  return (
    <section className="hero" id="home">
      <div className="hero-banner">
        <h1>My name is Mark</h1>
        <p>
          I'm an aspiring developer and IT professional. Having recently
          completed a Master of IT at Deakin University, I'm excited to make a
          difference in the world one line of code at a time.
        </p>
        <Button href="#projects" variant="contained">
          My Projects
        </Button>
      </div>
    </section>
  );
};

export default Hero;
