import Title from "./Title";
const About = () => {
  return (
    <section className="section" id="about">
      <Title title="about" subTitle="me" />

      <div className="section-center about-center">
        <article className="about-info">
          <p>
            I have completed a Master of IT with a major in Cyber Security at
            Deakin University. My passion for technology and desire to protect
            against cyber threats has driven me to pursue a career in this
            field.
          </p>
          <p>
            In addition to my studies, I have gained hands-on experience in web
            development, allowing me to bring both technical and creative skills
            to the table. I am always seeking new opportunities to expand my
            knowledge and skills, and am constantly on the lookout for
            challenging projects that will push me to grow.
          </p>
          <p>
            This website is a platform for me to showcase my projects and
            achievements, as well as a place to document my journey as I
            continue to learn and grow in the tech industry. Right now it's a
            barebones testbed, but I am excited to share my experiences and
            developments with the world and hope to inspire others to pursue
            their passions in technology.
          </p>
        </article>
      </div>
    </section>
  );
};

export default About;
