import { projects } from "../data";

import Title from "./Title";
const Projects = () => {
  return (
    <section className="section" id="projects">
      <Title title="featured" subTitle="projects" />

      <div className="section-center featured-center">
        {projects.map((project) => {
          const { id, image, title, text } = project;
          return (
            <article className="project-card" key={id}>
              <div className="project-img-container">
                <img src={image} className="project-img" alt={title} />
              </div>
              <div className="project-info">
                <div className="project-title">
                  <h4>{title}</h4>
                </div>
                <p>{text}</p>
              </div>
            </article>
          );
        })}
      </div>
    </section>
  );
};

export default Projects;
