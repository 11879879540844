import project1 from "./images/img1.jpg";
import project2 from "./images/img4.jpg";
import project3 from "./images/img3.jpg";
import project4 from "./images/img2.jpg";

export const pageLinks = [
  { id: 1, href: "#home", text: "home" },
  { id: 2, href: "#about", text: "about" },
  { id: 3, href: "#values", text: "values" },
  { id: 4, href: "#projects", text: "projects" },
];

export const socialLinks = [
  { id: 1, href: "https://github.com/MarkPremier", icon: "fab fa-github" },
  {
    id: 2,
    href: "https://www.linkedin.com/in/mark-premier/",
    icon: "fab fa-linkedin",
  },
];

export const values = [
  {
    id: 1,
    icon: "fas fa-wallet fa-fw",
    title: "value for clients",
    text: "Having experience in customer service, I know the importance to delivery value to clients.",
  },
  {
    id: 2,
    icon: "fas fa-tree fa-fw",
    title: "work/life balance",
    text: "A happy worker is a productive worker, ensuring the the wellbeing of a team is a pathway to success.",
  },
  {
    id: 3,
    icon: "fas fa-school fa-fw",
    title: "endless learning",
    text: "I want to learn something new everyday. My interest in IT makes this an easy task.",
  },
];

export const projects = [
  {
    id: 1,
    image: project1,
    title: "Cycle-On",
    text: "My first group project undertaken during my Masters degree. A website built on React that aims to bringthe Uber experience to eBikes, including route calculations, ETAs, and costs based on distance travelled. Made using the Google Map API.",
  },
  {
    id: 2,
    image: project2,
    title: "EVCFLO",
    text: "Developed with a team during the Capstone year of my Masters degree. A website used to provided information about EV charging locations around Melbourne. This includes data about locations, usage, and connector types.",
  },
  {
    id: 3,
    image: project3,
    title: "This Website!",
    text: "This website was created to not only experiment with React, but also with AWS. In addition to this website front-end, I have created a CI/CD pipeline within AWS, along with development and production branches.",
  },
  {
    id: 4,
    image: project4,
    title: "Proxmox Homelab",
    text: "A homelab which runs Proxmox. This allows me to quickly spin up VMs and containers to test applications and run code.",
  },
];
